<template>
  <b-dropdown variant="primary m-0 rounded">
    <template #button-content>
      <span
        v-if="selectedLocalValues && selectedLocalValues.length"
        class="selected-filters px-2 py-1 mr-1"
        >{{ selectedLocalValues.length }}</span
      >
      {{ $t('selectAttributes') }}
    </template>
    <div class="px-2 attributes-dropdown-content">
      <div v-for="option in options" :key="option.id">
        <b-form-checkbox
          v-model="selectedLocalValues"
          :value="option.id"
          @input="$emit('update-selection', selectedLocalValues)"
        >
          <span class="option-label">
            {{ option.name }}
          </span>
        </b-form-checkbox>
      </div>
    </div>
  </b-dropdown>
</template>
<script>
export default {
  name: 'AttributesDropdown',
  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data: () => ({
    selectedLocalValues: []
  }),
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (this.selectedLocalValues !== val) {
          this.$nextTick(() => {
            this.selectedLocalValues = val
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.attributes-dropdown-content {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.option-label {
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  max-width: 20ch;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selected-filters {
  border-radius: 4px;
  color: white;
  background-color: #9dc3c2;
  font-size: 12px;
  font-weight: 500;
}
</style>
